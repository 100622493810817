import { createGlobalStyle, css } from 'styled-components';
import cssVars from 'css-vars-from-json';
import { colors, breakPoints } from '.';
import { Roboto, Inter, BWseidoRaw } from '@/styles/fonts';
import 'allotment/dist/style.css';

const generateCssVars = () => {
  return cssVars({ color: colors, breakpoint: breakPoints });
};

const generatedRootVars = css`
  :root {
    ${generateCssVars()}
    --font-family-roboto: ${Roboto.style.fontFamily};
    --font-family-inter: ${Inter.style.fontFamily};
    --font-family-bwseido-raw: ${BWseidoRaw.style.fontFamily};
  }
`;

const GlobalStyle = createGlobalStyle`

${generatedRootVars}
  
  html {
    background: ${colors.secondary.lightGray1};
    color: ${colors.primary.gray};
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body {
    padding: 0;
    margin: 0;
    /* font-family: var(--font-bwseido-raw), Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
    font-family: var(--font-family-inter), Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 14px;
  }

  input, textarea, select { 
    font-family:inherit;
    font-size:inherit; 
  }

  /* *:focus {
    outline-color: transparent;
  } */

  p {
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 120%;
    &.block {
      display: block;
    }
  }

  h1 {
    font-family: var(--font-bwseido-raw);
    font-size: 56px;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 48px;
    }
  }
  
  h2 {
    font-family: var(--font-bwseido-raw);
    font-size: 48px;
    line-height: 120%;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 48px;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 34px;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 24px;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 18px;
    line-height: 150%;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 16px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    &.link {
      color: ${colors.secondary.blue3};
      &:hover {
        text-decoration: underline;
      }
    }
    &.hover-link {
      &:hover {
        color: ${colors.secondary.blue3};
        text-decoration: underline;
      }
    }
    &.link-underline {
      text-decoration: underline;
      color: ${colors.secondary.blue3};
    }
    &.bold {
      font-weight: bold;
    }
  }

  .dim {
    opacity: 0.5;
  }

  .base-font {
    font-family: var(--font-bwseido-raw), Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  .mono {
    font-family: var(--font-family-mono) !important;
  }

  pre {
    white-space: pre-wrap; 
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  .error-text {
    color: ${colors.semantic.negative.dark};
  }

  .hide-mobile {
    @media (max-width: ${breakPoints.mobile}) {
      display: none !important;
    }
  }
  .show-mobile {
    @media (min-width: ${breakPoints.mobile}) {
      display: none !important;
    }
  }

  .hide-mobile-portrait {
    @media (max-width: ${breakPoints.mobilePortrait}) {
      display: none !important;
    }
  }
  .show-mobile-portrait {
    @media (min-width: calc(${breakPoints.mobilePortrait} + 1px)) {
      display: none !important;
    }
  }

  .text-line-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default GlobalStyle;
